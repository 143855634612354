<template>
  <div class="home">
    <img class="akicon-logo" src="../assets/public/akicon-logo_tate.png" alt="akicon-logo">
    <div class="home-container">
      <section>
        <div class="home-sub-title">
          <span>{{ $t("login_email_title") }}</span>
        </div>

        <v-text-field type="email" outlined v-model="email" />
      </section>
      <section>
        <div class="home-sub-title">
          <span>{{ $t("login_password_title") }}</span>
        </div>

        <v-text-field type="password" outlined v-model="password" />
      </section>
      <div v-if="isLoginError" class="home-error-message">
        <span>{{ $t("login_error_message") }}</span>
      </div>
      <div class="home-button" @click="login()">
        <span class="home-button-text">{{ $t("login_btn") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
  name: "Home_login",
  data() {
    return {
      email: "",
      password: "",
      isLoginError: false,
    };
  },

  methods: {
    login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          sessionStorage.setItem("login_email", user.email);
          sessionStorage.setItem("type", "akiya");
          this.$router.push("/akiya");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          this.isLoginError = true;
          console.error("errorCodem", errorCode);
          console.error("errorMessage", errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .akicon-logo {
    height: 13%;
    width: auto;
    margin-top: 5%;
  }

  .home-triangle-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .home-sub-title {
    font-size: max(14px, 1.2vw);
    margin-bottom: 5px;
  }
  .home-error-message {
    font-size: max(10px, 1vw);
    margin-bottom: 0px;
    color: red;
  }
  .home-triangle-bottom {
    width: 75%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .home-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 20%;
    z-index: 1;
    height: 60%;
    width: 80%;
    background-image: url("../assets/background/bg.png");
    background-position: center;
    background-size: contain;

    .home-title {
      font-size: max(22px, 2.5vw);
      font-weight: bold;
      color: #132d7a;
    }

    .home-description {
      text-align: center;
      font-size: max(5px, 1vw);
      white-space: pre-line;
      font-weight: bold;
      margin-top: 10px;
    }

    .home-button {
      margin-top: 30px;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 30%;
      background-color: #132d7a;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        opacity: 0.8;
      }

      .home-button-text {
        color: #fafafa;
        font-size: max(18px, 1.5vw);
        font-weight: bold;
        letter-spacing: 0.8rem;
      }
    }
  }
}
</style>
